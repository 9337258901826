
.chat-container {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 10px;
    display: flex;
    flex-direction: column;
    height: 2000px;
    font-size: small; 
    
  }
  /* .chat-container:hover{
    transform: scale(1.03);
    transition: transform 0.2s ease;
  } */
  .formleft{
    margin-left: 20px;

  }

  .mic-on{
    align-content: center;
    border: 0;
  }
  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
  }

  .chat-header {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .chat-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .clear-button {
    background-color: #ff5b5b;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .clear-button:hover {
    background-color: #ff3030;
    transform: scale(1.04);
    transition: transform 0.2s ease;
  }
  
  .chat-content {
    padding: 10px;
    overflow-y: scroll;
    flex-grow: 1;
  }
  
  .chat-bubble {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px;
    max-width: 70%;
    opacity: 0.2; /* Start with 0 opacity */
    transition: opacity 0.5s ease; 
  }
  .message-label{
    color: #000;
  }
  
.user-bubble {
  text-align: right;
  background-color: #1abc9c;
  color: white;
  border-radius: 20px 20px 20px 20px; /* Adjust border-radius as needed */
  margin: 5px;
  padding: 10px;
  margin-left: 50px;
  /* max-width: 70%;  */
  /* Adjust as needed */
  align-self: flex-end;
}

.user-bubble:hover{
  transform: scale(1.03);
  transition: transform 0.2s ease;
}

.assistant-bubble {
  text-align: left;
  background-color: #3498db;
  color: white;
  border-radius: 20px 20px 20px 20px; /* Adjust border-radius as needed */
  margin: 5px;
  padding: 10px;
  max-width: 70%; /* Adjust as needed */
  align-self: flex-start;
}
.assistant-bubble:hover{
  transform: scale(1.03);
  transition: transform 0.2s ease;
}
  
  .chat-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
  }
  
  .chat-input {
    flex-grow: 1;
    padding: 5px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .send-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  